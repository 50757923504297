import React from 'react';
import { Box, Typography } from '@mui/material';

const NotFound: React.FC = () => {
  return (
    <Box
      textAlign="center"
      height="320px"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div>
        <Typography
          variant="h1"
          component="div"
        >
          404
        </Typography>
        <Typography
          variant="h5"
          component="div"
        >
          Not Found
        </Typography>
        <p>The page you’re looking for can’t be found.</p>
      </div>
    </Box>
  );
};
export default NotFound;
