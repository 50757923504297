import React, { ChangeEventHandler, useState } from 'react';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, IconButton, Rating, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { green, red } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import TypeWordRecord from '../../Types/TypeWordRecord';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const BulkRegist: React.FC = () => {
  const [data, setData] = useState<TypeWordRecord[]>([]);
  const [saveable, setSaveable] = useState(false);
  const [saving, setSaving] = useState(false);

  /**
   * ファイルを選択後
   * @param event 
   * @returns 
   */
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    resetFlag();
    setData([]);
		const files = event.currentTarget.files;
    const reader = new FileReader();
		if (!files || files?.length === 0) return;
    const file = files[0];
    reader.onload = async(e) => {
      if (! (e.target && e.target.result)) {
        return;
      }
      // 改行で分解
      const lines = e.target.result.toString().split("\n");
      let result: TypeWordRecord[] = [];
      lines.forEach(line => {
        const columns = line.split(',');
        if (columns.length !== 4) {
          return;
        }
        const record: TypeWordRecord = getWordRecord(columns[0], columns[2], columns[1], parseInt(columns[3]));
        result.push(record);
      });
      setData(result);
      setSaveable(result.length > 0);
    }
    reader.readAsText(file)
    console.log(file);
  };

  const getWordRecord = (word: string, clue: string, mean: string, level: number): TypeWordRecord => {
    return {
      word: {
        word,
        clue: {
          clue,
          mean,
          level,
        }
      },
      isEdit: false,
      result: null,
      message: '',
    };
  };

  /**
   * 保存ボタン押下
   */
  const handleSave = () => {
    setSaving(true);
    let remain = data.length;
    let result: TypeWordRecord[] = [];
    data.forEach(record => {
      if (record.result !== null) {
        return;
      }
      axios.post('words/', record.word)
      .then(response => {
        record.result = "success";
        result.push(record);
      })
      .catch((e) => {
        // @todo: エラーを格納したり
        record.result = "error";
        record.message = e.response.data.message;
        result.push(record);
      })
      .finally(() => {
        remain--;
        if (remain <= 0) {
          resetFlag();
          setData(result);
        }
      });
    })
  };

  /**
   * state系をクリアする
   * @param resultFlag 
   */
  const resetFlag = () => {
    setSaveable(false);
    setSaving(false);
  }

  /**
   * 追加ボタン押下
   */
  const handleAdd = () => {
  }

  const getBgColor = (result: string | null): string => {
    switch (result) {
      case 'success':
        return green[100];
      case 'error':
        return red[100];
      default:
        return '';
    }
  }

  const Record: React.FC<{record: TypeWordRecord}> = (props) => {
    if (props.record.isEdit) {
      return (<EditRecord record={props.record} />);
    } else {
      return (<ViewRecord record={props.record} />);
    }
  };

  const EditRecord: React.FC<{record: TypeWordRecord}> = (props) => (
    <TableRow>
      <TableCell><TextField label="単語" value={props.record.word.word}/></TableCell>
      <TableCell><TextField label="意味" value={props.record.word.clue.mean} /></TableCell>
      <TableCell><TextField label="ヒント" value={props.record.word.clue.clue} /></TableCell>
      <TableCell><TextField label="レベル" value={props.record.word.clue.level} /></TableCell>
      <TableCell>{props.record.result}</TableCell>
    </TableRow>
  );

  const ViewRecord: React.FC<{record: TypeWordRecord}> = (props) => (
    <TableRow sx={{backgroundColor: getBgColor(props.record.result)}}>
      <TableCell>{props.record.word.word}</TableCell>
      <TableCell>{props.record.word.clue.mean}</TableCell>
      <TableCell>{props.record.word.clue.clue}</TableCell>
      <TableCell>
        <Rating
          value={props.record.word.clue.level}
          readOnly
        />
      </TableCell>
      <TableCell>{props.record.result}</TableCell>
    </TableRow>
  );
  return (
    <>
      <h1>一括登録</h1>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        Read a file
        <VisuallyHiddenInput
          type="file"
          onChange={handleFileChange}
        />
      </Button>
      <hr />
      <Typography component="h3">登録待機中</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>単語</TableCell>
            <TableCell>意味</TableCell>
            <TableCell>ヒント</TableCell>
            <TableCell>レベル</TableCell>
            <TableCell>結果</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <Record key={idx} record={row} />
          ))}
        </TableBody>
      </Table>
      <Box textAlign="right">
        <IconButton
          color="primary"
          onClick={handleAdd}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Box>
        <LoadingButton
          variant="contained"
          disabled={!saveable}
          loading={saving}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </Box> 
    </>
  );
}
export default BulkRegist;
