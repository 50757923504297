import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { CROSSWORD } from '../../const';

/**
 * クロスワード 盤面
 */
const ABoard = styled("table")({
  borderCollapse: 'collapse',
});

export const Board: React.FC<{children: ReactNode}> = (props) => {
  return (
    <ABoard>
      <tbody>
        {props.children}
      </tbody>
    </ABoard>
  );
}

/**
 * クロスワード 行
 */
export const Line = styled("tr")({});

/**
 * クロスワード セル
 */
const StyledCell = styled("td")({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: CROSSWORD.BORDER_COLOR,
  width: CROSSWORD.SIDE_SIZE,
  height: CROSSWORD.SIDE_SIZE,
  verticalAlign: 'middle',
  position: 'relative',
  cursor: 'pointer',
});
type CellProps = {
  children: ReactNode;
  selected?: boolean;
  target?: boolean;
  onClick?: () => void;
};
export const Cell: React.FC<CellProps> = (props) => {
  const getBgColor = () => {
    if (props.selected) {
      return '#aaccff';
    } else if (props.target) {
      return '#cceeff';
    }
    return '';
  }
  return (
    <StyledCell
      style={{ backgroundColor: getBgColor() }}
      onClick={props.onClick}
    >
      {props.children}
    </StyledCell>
  );
}

/**
 * クロスワード セル 選択中
 */
export const SelectedCell = styled(Cell)({
  backgroundColor: '#cce9ff',
});

/**
 * クロスワード セル内 ヒント用数字
 */
export const Index = styled("span")({
  position: 'absolute',
  left: 0,
  top: 0,
  fontSize: CROSSWORD.FONT_SIZE,
});

/**
 * クロスワード セル内 文字
 */
export const Charactor = styled("span")({
  textAlign: 'center',
  display: 'block',
  width: '100%',
});

/**
 * クロスワード 黒セル
 */
export const Black = styled("td")({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: CROSSWORD.BORDER_COLOR,
  backgroundColor: CROSSWORD.BLACK,
  width: CROSSWORD.SIDE_SIZE,
  height: CROSSWORD.SIDE_SIZE,
  display: 'table-cell',
});
