import React from 'react';
import { Black, Cell, Charactor, Index } from './Crossword';
import TypeBoardPosition from '../../Types/TypeBoardPosition';

type Props = {
  /** @var string 盤面表示文字 */
  char: string;
  /** @var TypeBoardPosition このセルの位置 */
  position: TypeBoardPosition;
  /** @var number 番号 */
  number?: number;
  /** @var boolean 選択されているか */
  selected?: boolean;
  /** @var boolean 対象かどうか */
  target?: boolean;
  /** @var クリックhook */
  onClick?: (pos: TypeBoardPosition) => void;
};
const CrosswordCell: React.FC<Props> = (props) => {
  /**
   * セルをクリック（黒マス以外）
   */
  const handleClick = () => {
    if (props.onClick) {
      console.log('CLICK');
      // console.log(props.onClick);
      props.onClick(props.position);
    }
  };

  if (props.char === '*') {
    // 黒マスの場合
    return <Black />
  } else {
    // 選択中
    return (
      <Cell
        selected={props.selected}
        target={props.target}
        onClick={handleClick}
      >
        <Index>{props.number}</Index>
        <Charactor>{props.char}</Charactor>
      </Cell>
    );
  }
};
export default CrosswordCell;
