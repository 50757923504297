import React, { useState } from 'react';
import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const HowToUse: React.FC = () => {
  const [howToUseDialog, setHowToUseDialog] = useState(false); // 使い方ダイアログ

  const handleClick = () => {
    setHowToUseDialog(true);
  }

  const handleClose = () => {
    setHowToUseDialog(false);
  }

  return (
    <>
      <Alert severity="info" sx={{ mb: 2, display: { xs: 'none', md: 'flex' } }}>
        <AlertTitle>使い方</AlertTitle>
        ワードパズルで利用できる言葉を検索できます。<br />
        検索欄に検索したい文字を入れてください。<br />
        ひらがな、カタカナのどちらでも検索できますが、検索結果はひらがなです。<br />
        1文字のワイルドカード「?」が利用できます。<br />
        例：「あいう?お」「あ?う?お」「???えお」
      </Alert>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Button
          variant="contained"
          color="info"
          startIcon={<InfoIcon />}
          onClick={handleClick}
          sx={{ mb: 2 }}
        >
          使い方
        </Button>
        <Dialog
          open={howToUseDialog}
          onClose={handleClose}
        >
          <DialogTitle>
            使い方
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            ワードパズルで利用できる言葉を検索できます。<br />
            検索欄に検索したい文字を入れてください。<br/>
            ひらがな、カタカナのどちらでも検索できますが、検索結果はひらがなです。<br />
            1文字のワイルドカード「?」が利用できます。<br />
            例：「あいう?お」「あ?う?お」「???えお」
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};
export default HowToUse;
