import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import './index.css';
// import App from './App';
import Router from './Router';
import Header from './Components/Common/Header'
import Footer from './Components/Common/Footer'

import reportWebVitals from './reportWebVitals';

// axiosのデフォルト設定
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['X-RapidAPI-Key'] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.common['X-RapidAPI-Host'] = 'japanese-crossword.p.rapidapi.com';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <App /> */}
      <Header />
      <Router />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
