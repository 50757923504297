import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const period = (): string => {
  const start: number = 2023;
  const end: number = new Date().getFullYear();
  if (start === end) {
    return start.toString();
  }
  return start.toString() + '-' + end.toString();
};

const Copyright = (): JSX.Element => {
  return (
    <Typography
      variant="body2"
      align="center"
      sx={{ bgcolor: 'black', color: 'white' }}
    >
      <Typography
        variant="body2"
        component="span"
      >
        Copyright &copy;
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{ px: 1 }}
      >
        { period() }
      </Typography>
      <Link color="inherit" href="http://www.red-wing.biz/">
        Redwing Software
      </Link>
    </Typography>
  );
};

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        pt: 2,
        mt: 7
      }}
      component="footer"
    >
      <Copyright />
    </Box>
  );
}
export default Footer;
