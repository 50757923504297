export const CELL_BLACK = '*';
export const CROSSWORD = {
  SIDE_SIZE: '40px',
  FONT_SIZE: '0.6em',
  BORDER_COLOR: '#000000',
  BLACK: '#000000',
};
/** @var string 横向き */
export const VECTOR_ACROSS: string = 'across';
/** @var string 下向き */
export const VECTOR_DOWN: string = 'down';
