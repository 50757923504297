import React, { MouseEvent, useMemo, useState } from 'react';
import { AppBar, Box, Button, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { Link as RLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { JSX } from 'react/jsx-runtime';

type Page = {
  text: string;
  href?: string;
  to?: string;
  target?: string;
  debug?: boolean;
};

const pages: Page[] = [
  {text: '単語検索', to: '/'},
  {text: 'クロスワード作成', to: '/crossword/create', debug: true},
  {text: 'クロスワード エディタ（作成中）', to: '/crossword/edit'},
  {text: 'Contacts', href: 'https://www.red-wing.biz/contacts', target: '_blank'},
];

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const LinkButton: React.FC<{page: Page}> = (props) => {
  if (props.page.href) {
    return (
      <Button
        component={Link}
        href={props.page.href ?? ''}
        target={props.page.target ?? ''}
        sx={{ my: {xs:0, md:2}, color: {xs:'black', md:'white'}, display: {xs:'inline', md:'block'} }}
      >
        {props.page.text}
      </Button>
    );
  } else {
    return (
      <Button
        component={RLink}
        to={props.page.to ?? ''}
        target={props.page.target ?? ''}
        sx={{ my: {xs:0, md:2}, color: {xs:'black', md:'white'}, display: {xs:'inline', md:'block'} }}
      >
        {props.page.text}
      </Button>
    );
  }
};

const Header: React.FC = () => {
  const query = useQuery();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const isMenu = (): boolean => {
    return Boolean(anchorEl);
  }
  const HeaderItems = (menu?: boolean) => {
    const list: JSX.Element[] = [];
    pages.forEach((page, idx) => {
      if (!page.debug || (page.debug && query.get("debug"))) {
        if (menu) {
          list.push(
            <MenuItem key={idx}>
              <LinkButton page={page} />
            </MenuItem>
          );
        } else {
          list.push(<LinkButton key={idx} page={page} />);
        }
      }
    })
    return list;
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={isMenu()}
          onClose={() => setAnchorEl(null)}
        >
          {HeaderItems(true)}
        </Menu>
        <Typography
          component={RLink}
          variant="h6"
          to="/"
          sx={{
            mr: 2,
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          {process.env.REACT_APP_TITLE}
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {HeaderItems()}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Header;
