import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Backdrop, Box, CircularProgress, Rating, TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';

const Edit: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [mean, setMean] = useState<string>('');
  const [clue, setClue] = useState<string>('');
  const [level, setLevel] = useState<number>(0);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    axios.get('clues/' + id)
    .then(response => {
      setMean(response.data.mean ?? '');
      setClue(response.data.clue ?? '');
      setLevel(response.data.level ?? 0);
      setLoading(false);
    })
    .catch(() => {
      // @todo
    });
  }, [id]);


  const handleClick = () => {
    setSaving(true);
    if (error.trim() !== '') {
      setError('ヒントは必須です');
      return;
    }
    axios.put('clues/' + id, {mean, clue, level})
    .then(() => {
      navigate('/');
    })
    .catch(error => {
      setError(error.response.data.message);
    })
    .finally(() => {
      setSaving(false);
    });
  };

  const handleAlertClose = () => {
    setError('');
  };

  return (
    <>
      <h2>ヒント編集</h2>
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
      <Alert
        severity="error"
        sx={{ pb: 2, display: error === '' ? 'none' : 'flex'}}
        onClose={handleAlertClose}
      >
        {error}
      </Alert>
      <TextField
        label="意味"
        value={mean}
        fullWidth
        onChange={(e) => setMean(e.target.value)}
        sx={{ pb: 2 }}
      />
      <TextField
        label="ヒント"
        required
        value={clue}
        fullWidth
        onChange={(e) => setClue(e.target.value)}
        sx={{ pb: 2 }}
      />
      <Box>
        <Rating
          value={level}
          onChange={(e,v) => setLevel(v ?? 0)}
          sx={{ pb: 2 }}
        />
      </Box>
      <LoadingButton
        loading={saving}
        variant="contained"
        onClick={handleClick}
      >
        更新
      </LoadingButton>
    </>
  );
}
export default Edit;
