import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Backdrop, Box, CircularProgress, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const Edit: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [word, setWord] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    axios.get('words/' + id)
    .then(response => {
      setWord(response.data.word.word);
      console.log(response.data);
    })
    .catch(error => {
      // @todo:
    });
  }, [id]);

  const handleSave =  () => {
    setSaving(true);
    axios.put('words/' + id, {word})
    .then(() => {
      navigate('/');
    });
  }

  /**
   * テキストエリアの変更
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value);
  }

  return (
    <>
      <h2>単語編集</h2>
      <Backdrop
        open={word === null}
      >
        <CircularProgress />
      </Backdrop>
      <Box>
        <TextField
          label="単語"
          value={word}
          onChange={handleChange}
        />
        <LoadingButton
          loading={saving}
          onClick={handleSave}
        >
          更新
        </LoadingButton>
      </Box>
    </>
  );
}
export default Edit;
