import React, { useMemo, useState } from 'react';
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const WordRegist: React.FC = () => {
  const query = useQuery();

  const [open, setOpen] = useState(false);
  const [word, setWord] = useState('');
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState<{color: AlertColor, message: string}>({color: 'success', message: ''});
  const [submitting, setSubmitting] = useState(false);
  const onOpen = () => {
    setOpen(true);
  }
  const onClose = () => {
    console.log('CLOSE');
    setOpen(false);
  }
  const onSave = () => {
    setError(null);
    setSubmitting(true);
    let data = {
      word: word,
    };
    console.log('POSTING', data);
    axios.post('words/', data)
    .then(response => {
      console.log(response.data);
      setSnackbar({color: 'success', message: 'Registed!'});
      setWord('');
      setOpen(false);
    })
    .catch(error => {
      setError(error.response.data.message);
    })
    .finally(() => {
      setSubmitting(false);
    });
    return false;
  }
  const handleSnackbarClose = () => {
    setSnackbar({color: 'success', message: ''});
  }
  const Error = (): JSX.Element => {
    if (error) {
      return (
        <Alert severity='error'>{error}</Alert>
      );
    } else {
      return(<></>);
    }
  }

  const RegistButton: React.FC = () => {
    if (query.get("debug")) {
      return (
        <Button
          variant="contained"
          onClick={onOpen}
          sx={{ mb: 2 }}
        >
          単語登録
        </Button>
      );
    } else {
      return <></>;
    }
  };
  return (
    <>
      <Snackbar
        open={snackbar.message !== ''}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbar.color}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <RegistButton />
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>単語登録</DialogTitle>
        <DialogContent>
          <Error />
          <TextField
            label="単語(*)"
            variant="standard"
            fullWidth
            sx={{ mb: 1 }}
            onChange={ (event) => setWord(event.target.value) }
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={onClose}
          >
            キャンセル
          </Button>
          <LoadingButton
            loading={submitting}
            variant="contained"
            onClick={onSave}
          >
            登録
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default WordRegist;
