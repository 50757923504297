import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import TypeClue from '../../../Types/TypeClue';

type Props = {
  clues: TypeClue[];
};
const Clue: React.FC<Props> = (props) => {
  return (
    <Paper>
      <h3>ヒント一覧</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>ヒント</TableCell>
              <TableCell>意味</TableCell>
              <TableCell>Control</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.clues.map(clue => (
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>{clue.clue}</TableCell>
                <TableCell>{clue.mean}</TableCell>
                <TableCell>Control</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
export default Clue;
