import React, { useState } from 'react';
import { MenuItem, Paper, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import CrosswordBoard from '../../../Libraries/CrosswordBoard';
import TypeBoardPositionSelect from '../../../Types/TypeBoardPositionSelect';
import SelectedWord from './ClueSelect/SelectedWord';
import WordCandidates from './ClueSelect/WordCandidates';

type Props = {
  board: CrosswordBoard | undefined;
  positions: TypeBoardPositionSelect[] | undefined;
}
const ClueSelect: React.FC<Props> = (props) => {
  const [wordLength, setWordLength] = useState(2);

  /**
   * 文字数変更ハンドラ
   * @param event 
   */
  const handleWordLengthChange = (event: SelectChangeEvent) => {
    setWordLength(parseInt(event.target.value));
  }

  /**
   * 選択している箇所の単語情報を取得
   * @returns string[]
   */
  const getWord = (): string[] => {
    if (props.positions === undefined) {
      return [];
    }
    const word = props.positions!.map(position => {
      const pos = {x: position.x, y: position.y};
      return props.board!.get(pos);
    });
    return word;
  }

  /**
   * 選択中の文字長も加味した単語情報
   * @returns
   */
  const getTargetWord = (): string[] => {
    return getWord().slice(0, wordLength);
  }

  /**
   * 選択可能な文字数を取得
   * @returns 
   */
  const getCharactorNumbers = (): number[]  => {
    if (props.board === undefined || props.positions === undefined) {
      console.log('BOAR or POSITIONS IS UNDEFINED');
      return [];
    }

    const result: number[] = [];
    const word = getWord();

    // ひとつ先に文字が入っている場合は選択NG
    for (let i = 1 ; i < word.length ; i++) {
      // 最後の文字は
      if (i !== word.length - 1) {
        if (word[i + 1] !== '') {
          continue;
        }
      }
      result.push(i + 1);
    }
    // console.log(result);
    return result;
  }

  const NoSelect: React.FC = () => {
    return (
      <p>盤面を選択してください</p>
    );
  }

  const SelectCharactors: React.FC = () => {
    return (
      <Select
        size="small"
        value={wordLength.toString()}
        onChange={handleWordLengthChange}
      >
        {getCharactorNumbers().map((num, idx) => (
          <MenuItem
            key={idx}
            value={num}
          >
            {num}文字
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <Paper>
      <h3>ヒント検索</h3>
      <SelectedWord word={getTargetWord()} />
      {getCharactorNumbers().length === 0 ? <NoSelect /> : <SelectCharactors />}
      <WordCandidates word={getTargetWord()}/>
    </Paper>
  );
}
export default ClueSelect;
