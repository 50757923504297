import React, { useState } from 'react';
import { CELL_BLACK, VECTOR_ACROSS, VECTOR_DOWN } from '../../const';
import SizeInput from '../../Components/Crossword/Edit/SizeInput';
import Board from '../../Components/Crossword/Edit/Board';
import TypeBoardPosition from '../../Types/TypeBoardPosition';
import TypeVector from '../../Types/TypeVector';
import Vector from '../../Components/Crossword/Edit/Vector';
import CrosswordBoard from '../../Libraries/CrosswordBoard';
import TypeBoardPositionSelect from '../../Types/TypeBoardPositionSelect';
import Clue from '../../Components/Crossword/Edit/Clue';
import ClueSelect from '../../Components/Crossword/Edit/ClueSelect';
import { Box, Grid, Paper } from '@mui/material';

const Edit: React.FC = () => {
  const [board, setBoard] = useState<CrosswordBoard>();
  // 選択している
  const [positions, setPositions] = useState<TypeBoardPositionSelect[]>();
  const [vector, setVector] = useState<TypeVector>(VECTOR_ACROSS);

  /**
   * 作成ボタン押下
   */
  const handleCreateButton = (width: number, height: number) => {
    // 何か入力されていたら確認ダイアログを出す
    let isAlert = false;
    if (board && !board.isEmpty()) {
      isAlert = true;
    }

    if (isAlert) {
      if (!window.confirm('入力が消えますがよろしいですか？')) {
        return;
      }
    }

    // Create empty board
    const emptyBoard = new CrosswordBoard(width, height);

    // DEBUG
    emptyBoard.set({x:1, y:1}, CELL_BLACK);
    emptyBoard.set({x:3, y:0}, 'あ');

    setBoard(emptyBoard);
    setPositions(undefined);
  }

  const refreshPositions = (pos: TypeBoardPosition, vec: TypeVector) => {
    const posList = [];
    const position: TypeBoardPositionSelect = {
      x: pos.x,
      y: pos.y,
      selected: true,
      target: true,
    };
    posList.push(position);

    const ax = vec === VECTOR_ACROSS ? 1 : 0;
    const ay = vec === VECTOR_DOWN ? 1 : 0;

    // 正方向
    let i = 1;
    while (true) {
      const tx = pos.x + ax * i;
      const ty = pos.y + ay * i;
      if (board?.get({x: tx, y: ty}) === CELL_BLACK) {
        break;
      }
      posList.push({
        x: tx,
        y: ty,
        selected: false,
        target: true,
      });

      i++;
    }

    // 負方向
    i = 1;
    while (true) {
      const tx = pos.x - ax * i;
      const ty = pos.y - ay * i;
      if (board?.get({x: tx, y: ty}) === CELL_BLACK) {
        break;
      }
      posList.push({
        x: tx,
        y: ty,
        selected: false,
        target: true,
      });

      i++;
    }

    posList.sort((a, b) => {
      const key = a.x === b.x ? 'y' : 'x';
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });

    setPositions(posList);
  }

  /**
   * セルをクリックした場合のhook
   * @param pos 
   */
  const handleCellClick = (pos: TypeBoardPosition) => {
    refreshPositions(pos, vector);
  }

  /**
   * 方向をクリックした場合のhook
   * @param newVector 
   */
  const handleVectorCllck = (newVector: TypeVector) => {
    setVector(newVector);
    const pos = positions?.filter(position => position.selected);
    if (pos) {
      refreshPositions(pos[0], newVector);
    }
  }

  const NoBoard: React.FC = () => {
    return (
      <Box sx={{ mt: 2 }}>
        サイズを選択してください
      </Box>
    );
  }

  const DisplayBoard: React.FC = () => {
    return (
      <Box sx={{ flexGrow: 1, px: 2 }}>
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={4}>
            <Paper>
              <h3>盤面</h3>
              <Vector vector={vector} onClick={handleVectorCllck}/>
              <Board
                board={board}
                positions={positions}
                onClick={handleCellClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <ClueSelect
              board={board}
              positions={positions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Clue clues={[]} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  const BoardEditor: React.FC = () => {
    if (board) {
      return <DisplayBoard/>
    } else {
      return <NoBoard />
    }
  }

  return (
    <>
      <h2>クロスワード エディタ（作成中）</h2>
      <SizeInput onClick={handleCreateButton}/>
      <BoardEditor />
    </>
  );
}
export default Edit;
