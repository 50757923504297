import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Top from './Pages/Top';
import CreateCrossword from './Pages/CreateCrossword';
import CrosswordEdit from './Pages/Crossword/Edit';
import NotFound from './Pages/NotFound';
import BulkRegist from './Pages/Words/BulkRegist';
import WordEdit from './Pages/Words/Edit';
import ClueEdit from './Pages/Clues/Edit';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Top />} />
      <Route path="/words/bulk" element={<BulkRegist />} />
      <Route path="/words/:id" element={<WordEdit />} />
      <Route path="/clues/:id" element={<ClueEdit />} />
      <Route path="/crossword/create" element={<CreateCrossword />} />
      <Route path="/crossword/edit" element={<CrosswordEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
