import TypeBoardPosition from "../Types/TypeBoardPosition";
import { CELL_BLACK } from "../const";

/**
 * クロスワード盤面管理クラス
 */
class CrosswordBoard {
  /** @var number 盤面幅 */
  width: number = 0;
  /** @var number 盤面高 */
  height: number = 0;
  /** @var string[][] 盤面データ */
  board: string[][] = [];

  /**
   * コンストラクタ
   * @param w 幅
   * @param h 高さ
   */
  constructor (w: number, h: number) {
    this.width = w;
    this.height = h;
    this.initBoard();
  }

  /**
   * 盤面初期化
   */
  initBoard(): void {
    const board = [];
    for (let y = 0 ; y < this.height ; y++) {
      const line = [];
      for (let x = 0 ; x < this.width ; x++) {
        line.push('');
      }
      board.push(line);
    }
    this.board = board;
  }

  /**
   * 盤面が空かどうか
   * @returns boolean
   */
  isEmpty(): boolean {
    if (this.board.length === 0) {
      return true;
    }
    for (let y = 0 ; y < this.board.length ; y++) {
      for (let x = 0 ; x < this.board[0].length ; x++) {
        if (this.get({x, y}) !== '') {
          return false;
        }
      }
    }
    return true;
  }

  get(pos: TypeBoardPosition): string {
    if (pos.x < 0 || pos.x >= this.width || pos.y < 0 || pos.y >= this.height) {
      return CELL_BLACK;
    }
    return this.board[pos.y][pos.x];
  }

  set(pos: TypeBoardPosition, char: string): void {
    this.board[pos.y][pos.x] = char;
  }
};
export default CrosswordBoard;
