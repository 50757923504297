import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, CardContent, CardHeader, Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import TypeWordDetail from '../../Types/TypeWordDetail';
import ClueRegist from './ClueRegist';
import TypeWord from '../../Types/TypeWord';
import TypeClue from '../../Types/TypeClue';
import { Link, useLocation } from 'react-router-dom';

const Detail: React.FC<{word: TypeWord}> = (props) => {
  const [detail, setDetail] = useState<TypeWordDetail>();

  const getDetail = (id: number) => {
    void axios.get('words/' + id)
    .then(response => {
      console.log(response.data);
      setDetail(response.data);
    })
    .catch(() => {
      setDetail(undefined);
    });
  }
  useEffect(() => {
    if (props.word && props.word.id) {
      getDetail(props.word.id);
    } else {
      setDetail(undefined);
    }
  }, [props.word]);

  return (
    <Card variant='outlined'>
      <CardHeader
        title={props.word.word}
        action={<ClueRegist word={ props.word } refresh={getDetail} />}
        sx={{
          fontWeight: 700,
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
        }}
      />
      <CardContent>
        <ClueDetail clues={detail ? detail.clues : []} />
      </CardContent>
    </Card>
  );
};

const NoWord: React.FC = () => {
  return (<div>単語が選択されていません</div>);
};

const NoClue: React.FC = () => {
  return (<div>ヒントが登録されていません</div>);
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const CluesTable: React.FC<{clues: TypeClue[]}> = (props) => {
  const query = useQuery();
  const TableHeaderCell = styled(TableCell)({
    backgroundColor: grey[200],
  });
  const EditHeader: React.FC = () => {
    if (query.get("debug")) {
      return (<TableHeaderCell>Edit</TableHeaderCell>);
    }
    return <></>;
  };

  const EditCell: React.FC<{id: number|null}> = (props) => {
    if (query.get("debug")) {
      return (
        <TableCell>
          <Button
            component={Link}
            to={"/clues/" + props.id}
            size="small"
          >
            <EditIcon />
          </Button>
        </TableCell>
      );
    } else {
      return <></>;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableHeaderCell>意味</TableHeaderCell>
            <TableHeaderCell>ヒント</TableHeaderCell>
            <TableHeaderCell>難易度</TableHeaderCell>
            <EditHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.clues.map(r => (
            <TableRow key={ r.id }>
              <TableCell>{ r.mean }</TableCell>
              <TableCell>{ r.clue }</TableCell>
              <TableCell>
                <Rating
                  value={r.level}
                  readOnly
                  sx={{ display:{xs: 'none', md: 'inline-flex'} }}
                />
                <Typography sx={{ display:{xs: '', md: 'none'} }}>
                  {r.level !== 0 ? r.level : '−'}
                </Typography>
              </TableCell>
              <EditCell id={r.id} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const ClueDetail: React.FC<{clues: TypeClue[]}> = (props) => {
  return (
    <div>
      {(() => {
        if (props.clues.length > 0) {
          return <CluesTable clues={props.clues} />;
        } else {
          return <NoClue />;
        }
      })()}
    </div>
  );
};

const WordDetail: React.FC<{word: TypeWord | undefined}> = (props) => {
  return (
    <div>
      {(() => {
        if (props.word) {
          return <Detail word={props.word} />;
        } else {
          return <NoWord />;
        }
      })()}
    </div>
  );
};

export default WordDetail;
