import React, { useState } from 'react';
import { Alert, Box, Snackbar, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Crossword from '@naari3/react-crossword-ja'
import axios from 'axios';
import { CluesInputOriginal } from '@naari3/react-crossword-ja/dist/types';

const defaultData = {across: {}, down: {}};
const CreateCrossword: React.FC = () => {
  const [data, setData] = useState<CluesInputOriginal>(defaultData);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(6);
  const [height, setheight] = useState(6);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const getCrossword = () => {
    setLoading(true);
    void axios.post('generate', {width, height})
    .then(response => {
      // console.log(response.data.clue);
      setData(response.data.clue);
    })
    .catch(error => {
      setData(defaultData);
      setSnackbarMessage(error.response.data.message);
      // console.log(error.status);
    })
    .finally(() => {
      setLoading(false)
    });
  }

  const handleClose = () => {
    setSnackbarMessage('');
  }

  return (
    <>
      <h2>クロスワード作成</h2>
      <TextField
        label="幅"
        type="number"
        value={width}
        inputProps={{ min:2, max: 20 }}
        onChange={e => setWidth(parseInt(e.currentTarget.value))}
      />
      <TextField
        label="高さ"
        type="number"
        value={height}
        inputProps={{ min:2, max: 20 }}
        onChange={e => setheight(parseInt(e.currentTarget.value))}
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={getCrossword}
      >
        作成
      </LoadingButton>
      <Box sx={{width: {sx: '100%', md:'50%'}, mx: 'auto'}}>
        <Crossword data={data} useStorage={false} />
      </Box>
      <Snackbar
        open={Boolean(snackbarMessage)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity="error"
          onClose={handleClose}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default CreateCrossword;
