import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import TypeWord from '../../Types/TypeWord';

type Props = {
  words: TypeWord[];
  selected: number | undefined;
  onClick: Function;
};

const WordList: React.FC<Props> = (props) => {
  const DataBody = () => {
    if (props.words.length > 0) {
      return (
        <TableBody>
          {props.words.map(row => (
            <TableRow
              key={row.id}
              hover
              selected={ row.id === props.selected }
            >
              <TableCell
                onClick={() => props.onClick(row)}
              >
                { row.word }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          <TableRow>
            <TableCell>
              No data.
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
  }
  return (
    <TableContainer
      component={Paper}
      sx={{maxHeight:'600px'}}
    >
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
            }}>
              単語
            </TableCell>
          </TableRow>
        </TableHead>
        <DataBody />
      </Table>
    </TableContainer>
  );
}
export default WordList;
