import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import SouthIcon from '@mui/icons-material/South';
import TypeVector from '../../../Types/TypeVector';
import { VECTOR_ACROSS, VECTOR_DOWN } from '../../../const';

type Props = {
  vector: TypeVector;
  onClick: (vector: TypeVector) => void;
};

const Vector: React.FC<Props> = (props) => {
  const handleClick = (vector: TypeVector) => {
    props.onClick(vector);
  }

  const getVariant = (vector: TypeVector) => {
    return props.vector === vector ? 'contained' : 'outlined';
  }

  return (
    <ButtonGroup variant="outlined">
      <Button
        variant={getVariant(VECTOR_DOWN)}
        onClick={() => handleClick('down')}
        size="small"
      >
        <SouthIcon />
      </Button>
      <Button
        variant={getVariant(VECTOR_ACROSS)}
        onClick={() => handleClick('across')}
        size="small"
      >
        <EastIcon />
      </Button>
    </ButtonGroup>
  );
}
export default Vector;
