import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';
import TypeWord from '../../../../Types/TypeWord';

type Props = {
  word: string[];
};

const WordCandidates: React.FC<Props> = (props) => {
  const [data, setData] = useState<TypeWord[]>([]);
  const [selected, setSelected] = useState('');
  useEffect(() => {
    let k = '';
    props.word.forEach(w => {
      k += w === '' ? '?' : w;
    });

    if (k.length > 0) {
      // ?だけの場合にエラーとなる対処
      if (k.match(/^\?+$/) !== null) {
        k = 'あ' + k.slice(1);
      }

      axios.get('words', {params: { k }})
      .then(response => {
        // console.log(response.data);
        setData(response.data);
      })
      .catch(error => {
        // @todo: エラーと分かるようにするべし
        console.error(error.response.data.message);
      });
    }
    console.log('CHANGE WORD');
  }, [props.word]);

  const isSelected = (word: string): boolean => {
    return word === selected;
  }

  const handleWordClick = (word: string) => {
    // console.log(word);
    setSelected(word);
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>単語</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((word, idx) => (
          <TableRow
            key={idx}
            hover
            selected={isSelected(word.word)}
            onClick={() => handleWordClick(word.word)}
          >
            <TableCell>{word.word}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
export default WordCandidates;
