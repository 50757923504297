import { Box } from '@mui/material';
import React from 'react';
import CrosswordCell from '../Cell';
import { Board as StyledBoard, Line } from '../Crossword';
import TypeBoardPosition from '../../../Types/TypeBoardPosition';
import TypeBoardPositionSelect from '../../../Types/TypeBoardPositionSelect';
import CrosswordBoard from '../../../Libraries/CrosswordBoard';

type Props = {
  board: CrosswordBoard | undefined;
  positions: TypeBoardPositionSelect[] | undefined;
  onClick?: (pos: TypeBoardPosition) => void;
};

const Board: React.FC<Props> = (props) => {
  /**
   * 選択されているかどうか
   * @param pos 
   * @returns boolean
   */
  const isSelected = (pos: TypeBoardPosition): boolean => {
    const position = props.positions?.find(p => p.x === pos.x && p.y === pos.y);
    return position?.selected ?? false;
  }

  /**
   * 選択されているか
   * @param pos 
   * @returns 
   */
  const isTarget = (pos: TypeBoardPosition): boolean => {
    const position = props.positions?.find(p => p.x === pos.x && p.y === pos.y);
    return position?.target ?? false;
  }

  return (
    <Box>
      <StyledBoard>
        {props.board?.board.map((line, y) => (
          <Line key={y}>
            {line.map((cell, x) => (
              <CrosswordCell
                key={x}
                number={undefined}
                char={cell}
                position={{x, y}}
                onClick={props.onClick}
                selected={isSelected({x, y})}
                target={isTarget({x, y})}
              />
            ))}
          </Line>
        ))}
      </StyledBoard>
    </Box>
  );
}
export default Board;
