import React, { useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Rating, TextField, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import TypeWord from '../../Types/TypeWord';
import TypeWordRegist from '../../Types/TypeWordRegist';

const ClueRegist: React.FC<{word: TypeWord, refresh: Function}> = (props) => {
  const [open, setOpen] = useState(false);
  const [clue, setClue] = useState('');
  const [mean, setMean] = useState('');
  const [level, setLevel] = useState(0);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const onOpen = () => {
    setOpen(true);
  }
  const onClose = () => {
    // console.log('CLOSE');
    setOpen(false);
  }
  const onSave = () => {
    setError(null);
    setSubmitting(true);
    let data: TypeWordRegist = {
      word: props.word.word,
      clue: {
        clue,
        mean,
        level,
      }
    };
    // console.log('POSTING', data);
    axios.post('words/', data)
    .then(response => {
      // console.log(response.data);
      setClue('');
      setMean('');
      setLevel(0);
      props.refresh(props.word.id);
      setOpen(false);
    })
    .catch(error => {
      setError(error.response.data.message);
    })
    .finally(() => {
      setSubmitting(false);
    });
    return false;
  }
  const Error = (): JSX.Element => {
    if (error) {
      return (
        <Alert severity='error'>{error}</Alert>
      );
    } else {
      return(<></>);
    }
  }
  const RegistButton = styled(Button)({
    color: 'primary',
    backgroundColor: 'white',
    fontWeight: 700,
    "&:hover": {
      backgroundColor: grey[300],
    }
  });
  return (
    <>
      <RegistButton variant="outlined"
        onClick={onOpen}
        >
        ヒント登録
      </RegistButton>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>ヒント登録</DialogTitle>
        <DialogContent>
          <Error />
          <Typography variant="h4">{ props.word.word }</Typography>
          <TextField
            value={clue}
            label="ヒント(*)"
            variant="standard"
            fullWidth
            sx={{ mb: 1 }}
            onChange={ (event) => setClue(event.target.value) }
          />
          <TextField
            value={mean}
            label="意味"
            variant="standard"
            fullWidth
            sx={{ mb: 1 }}
            onChange={ (event) => setMean(event.target.value) }
          />
          <Typography component="legend">
            難易度
          </Typography>
          <Rating
            value={level}
            onChange={ (event, newValue) => {
              setLevel(newValue ?? 0);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={onClose}
          >
            キャンセル
          </Button>
          <LoadingButton
            loading={submitting}
            variant="contained"
            onClick={onSave}
          >
            登録
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ClueRegist;
