import React from 'react';
import { Box } from '@mui/material';
import { Board as StyledBoard, Line, Cell, Charactor } from '../../Crossword';

/**
 * 選択中のマスを表示
 * @returns 
 */
const SelectedWord: React.FC<{word: string[]}> = (props) => {
  return (
    <Box sx={{ px: 2 }}>
      <StyledBoard>
        <Line>
          {props.word.map((c, idx) => (
            <Cell key={idx}>
              <Charactor>{c}</Charactor>
            </Cell>
          ))}
        </Line>
      </StyledBoard>
    </Box>
  );
}
export default SelectedWord;
