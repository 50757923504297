import React, { useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import TypeWord from '../Types/TypeWord';
import HowToUse from '../Components/Top/HowToUse';
import WordDetail from '../Components/Top/WordDetail';
import WordList from '../Components/Top/WordList';
import WordRegist from '../Components/Top/WordRegist';

const Top: React.FC = () => {
  const [searchWord, setSearchWord] = useState(''); // 検索用
  const [words, setWords] = useState<TypeWord[]>([]); // 検索結果
  const [word, setWord] = useState<TypeWord>();
  const [loading, setLoading] = useState(false); // 検索中

  /**
   * 検索ボタン押下
   */
  const onSearch = () => {
    const data = {k: searchWord};
    setLoading(true);
    setWord(undefined);
    void axios.get('words', {params: data})
      .then((response) => {
        // navigate('/contacts/complete');
        // console.log(response.data);
        setWords(response.data);
      })
      .catch(error => {
        // console.log(error.response.data);
        setWords([]);
      })
      .finally(() => {
        setLoading(false);
      });
    console.log(data);
  }
  return (
    <div style={{paddingLeft:'1rem', paddingRight:'1rem'}}>
      <h1>単語検索</h1>
      <Box
        sx={{ pb: 2 }}
      >
        <HowToUse />

        <TextField
          id="outlined-basic"
          size="small"
          label="検索"
          variant="outlined"
          value={searchWord}
          onChange={e => setSearchWord(e.target.value)}
          sx={{mr:1}}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onSearch}
        >
          検索
        </LoadingButton>
      </Box>

      <WordRegist />

      <Grid container>
        <Grid item md={4} xs={12}>
          <WordList
            words={words}
            selected={word && word.id ? word.id : undefined}
            onClick={setWord}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Box sx={{ pl: {xs: 0, md: 2}, pt: {xs: 2, md: 0} }}>
            <WordDetail word={word} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );

};
export default Top;
