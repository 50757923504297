import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SizeInput: React.FC<{onClick: any}> = (props) => {
  const [width, setWidth] = useState(5);
  const [height, setHeight] = useState(5);
  const [dialog, setDialog] = useState(false);

  const handleCreateButton = () => {
    props.onClick(width, height);
  }

  const handleDialogOpen = () => {
    setDialog(true);
  }

  const handleDialogClose = () => {
    setDialog(false);
  }

  return (
    <Box>
      <h3>サイズ指定</h3>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <TextField
          label="幅"
          type="number"
          value={width}
          size="small"
          inputProps={{ min:2, max: 20 }}
          onChange={e => setWidth(parseInt(e.currentTarget.value))}
        />
        <Typography
          sx={{ mx: 1 }}
        >
          x
        </Typography>
        <TextField
          label="高さ"
          type="number"
          value={height}
          size="small"
          inputProps={{ min:2, max: 20 }}
          onChange={e => setHeight(parseInt(e.currentTarget.value))}
        />
        <Button
          variant="contained"
          onClick={handleCreateButton}
          sx={{ ml: 1, width: '5rem' }}
        >
          作成
        </Button>
        <Typography sx={{ flexGrow: 1 }} />
        <Button
          variant="outlined"
          color="info"
          onClick={handleDialogOpen}
        >
          使い方
        </Button>
        <Dialog
          open={dialog}
          onClose={handleDialogClose}
        >
          <DialogTitle>
            使い方
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography gutterBottom>
              1. 幅と高さを指定して作成ボタンを押下してください。空のクロスワードの盤面が出来上がります。
            </Typography>
            <Typography gutterBottom>
              2. 盤面にて単語を入れたい所をクリックしてください。盤面の上にタテ・ヨコの切り替えがあります。
            </Typography>
            <Typography gutterBottom>
              3. ヒント検索にて文字数を選択して、出てきた単語一覧から選択してOKボタンを押下します。単語一覧にない場合は鉛筆アイコンをクリックして入力してください。
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}
export default SizeInput;
